

















import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'protect-page'
})
export default class extends Vue {
  joinHandle() {
    this.$router.push({
      path: '/music/index'
    })
  }
}
